import React, { useState, useEffect } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import "../ProductPage/productpage.css";
import img from "../../assets/img/img2/productpageImg.png";
import { Dropdown } from "react-bootstrap";
import cardImg from "../../assets/img/img2/cart1.png";
import data from "../../Json/dataFour";
import { useNavigate, Link, useLocation } from "react-router-dom";
// import { getProduct } from "../../api/api";
import axios from "axios";
import {
  deleteWishList,
  extrabondCategory,
  getAllProductCategory,
  getBrandFilter,
  getBrandsAllData,
  getcartId,
  getWhishlist,
  productData,
} from "../../api/api";
import Discription from "../Discription";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import ReactStars from "react-stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import { TbLayoutGrid } from "react-icons/tb";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { BsListTask } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { TailSpin } from "react-loader-spinner";
import { Modal, ModalHeader } from 'react-bootstrap';

export default function AllStpProducts() {
  const navigate = useNavigate();
  const location = useLocation();
  const storedData = useSelector((state) => state.profile);
  const { brandID, categoryById, ProductId } = location?.state;
  const [gridThree, setGridThree] = useState(true);
  const [gridFour, setGridFour] = useState(false);
  const [prodData, setProdData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoding, setisLoding] = useState(false);
  const [brandCategoryFIlter, setbrandCategoryFIlter] = useState([]);
  const [cartValues, setCartValues] = useState(false);
  const [mobileFilter, setMobileFilter] = useState(true);
  const [packssfilterData, setpackssfilterData] = useState([]);
  const [checkedbrandsfilterData, setcheckedbrandsfilterData] = useState([brandID]);
  const [checkedCategoriesfilterData, setcheckedCategoriesfilterData] =useState([]);
  const [checkedpackssfilterData, setcheckedpackssfilterData] = useState([]);
  const [isActive, setisActive] = useState(true);
  const [isActive2, setisActive2] = useState(false);
  const [seachValue, setseachValue] = useState("");
  const [filtercom, setFiltercom] = useState(false);
  const [showProduct, setShowProduct] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [noProdDataFound, setnoProdDataFound] = useState(false);
  const [itemsPerPage] = useState(20);
  const [brandsfilterData, setbrandsfilterData] = useState([]);
  const [CategoriesfilterData, setCategoriesfilterData] = useState([]);
  const [btnshow, setBtnShow] = useState(false);
  const [numCategoriesToShow, setNumCategoriesToShow] = useState(5);
  const [pageLoading, setPageLoading] = useState(true)
  const [xtraCategory, setXtraCategory] = useState('')
  const [showModal, setShowModal] = useState(false);

  console.log("--categoryId==>", CategoriesfilterData);

  const brandIdArr = [brandID];

  useEffect(()=>{
    console.log('brand id of the page ===>',brandID);
    categoryValue = [];
    packValue = [];
    packValue2 = [];
    categoryValue2=[];
    window.localStorage.removeItem('selectedItems')
  },[])

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 500);
    fetchProduct(categoryById);

    // if(brandID != 30){
    //   fetchProductFilters();
    // }else{
    //   extraBond()
    // }
    fetchProductFilters();
    gridTwoFuc();
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, [categoryById]);

  useEffect(() => {
    fetchProduct();
  }, [seachValue]);


  
  const gridFirstFunc = () => {
    console.log("hello");
    setGridThree(true);
    setGridFour(false);
    setisActive(false);
    setisActive2(true);
    // setGridFive(false)
  };

  const gridTwoFuc = () => {
    setGridFour(true);
    // setGridFive(false)
    setGridThree(false);
    setisActive(true);
    setisActive2(false);
  };


  const resFilterFunc = () => {
    console.log("fliter com", filtercom);
    setBtnShow(true);
    setFiltercom(!filtercom);
    setShowProduct(false);
    setMobileFilter(false);
    setnoProdDataFound(true)
  };

  const displayProduct = () => {
    setFiltercom(false);
    setShowProduct(true);
    setBtnShow(false);
    setMobileFilter(true);
    setnoProdDataFound(false)
  };

  const fetchProduct = () => {
    window.scrollTo(0, 0);
    var brandArr = JSON.parse(
      window.localStorage.getItem("BPbrandsCheckdDataLocal")
    );
    var categoryArr = JSON.parse(
      window.localStorage.getItem("BrandsCategoryCheckdDataLocal")
    );
    var packArr = JSON.parse(
      window.localStorage.getItem("BrandspackCheckedDataLocal")
    );

    var resbrandArr = JSON.parse(
      window.localStorage.getItem("resbrandsCheckdDataLocal")
    );
    var rescategoryArr = JSON.parse(
      window.localStorage.getItem("resCategoryCheckdDataLocal")
    );
    var respackArr = JSON.parse(
      window.localStorage.getItem("respackCheckedDataLocal")
    );

    setisLoding(true);
    const url = getBrandsAllData();
    const userData = {
      user_id: storedData?.userId,
      filter: true,
      //   brands: filtercom ? resbrandArr : brandArr,
      brands: brandIdArr,
      category: filtercom ? categoryValue2 : categoryValue,
      packs: filtercom ? packValue2 : packValue,
      search: seachValue,
    };

    console.log("--searchvalue---", seachValue);
    console.log("body of product fecthing =-=> ", userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(
          "product resp --> ",
          res.data.data[0].status,
          "lenth ==> ",
          res.data.data.length
        );
        if (res.data.status) {
          console.log("saurabh", res.data.data.length);
          setProdData(res.data.data);
          setTimeout(() => {
            setisLoding(false);
            setnoProdDataFound(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setisLoding(false);
            setnoProdDataFound(true);
          }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
        setisLoding(false);
        setnoProdDataFound(true);
      });
  };


  // const fetchProduct = (id) => {
  //     window.scrollTo(0, 0);
  //     setisLoding(true)
  //     const url = getAllProductCategory(id);
  //     console.log('--url---', url);
  //     var brandArr = JSON.parse(window.localStorage.getItem('BPbrandsCheckdDataLocal'))
  //     var categoryArr = JSON.parse(window.localStorage.getItem('BPCategoryCheckdDataLocal'))
  //     var packArr = JSON.parse(window.localStorage.getItem('BPpackCheckedDataLocal'))

  //     var resbrandArr = JSON.parse(window.localStorage.getItem('BPresbrandsCheckdDataLocal'))
  //     var rescategoryArr = JSON.parse(window.localStorage.getItem('BPresCategoryCheckdDataLocal'))
  //     var respackArr = JSON.parse(window.localStorage.getItem('BPrespackCheckedDataLocal'))

  //     const userData = {
  //         user_id: storedData.userId,
  //         brands: filtercom ? resbrandArr : brandArr,
  //         category: filtercom ? rescategoryArr : categoryArr,
  //         // packs: checkedpackssfilterData,
  //         packs: packArr,
  //         filter: true,
  //         search: seachValue

  //     }
  //     console.log('--userData--', userData);
  //     axios.post(url, userData, {
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //         },
  //     })
  //         .then((resp) => {
  //             console.log("state --> ", resp.data.data);
  //             setProdData(resp.data.data);
  //             console.log('---setprod--', setProdData);
  //             setTimeout(() => {
  //                 setisLoding(false)
  //             }, 2000);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setisLoding(false)
  //             console.log('--error--', err);
  //         });
  // };

  const sendProdId = (id) => {
    console.log(" Product id =>>>>>>>>SDdddddddd", id);
    // navigate(`/product/${id}`, { state: { productId: id } });
  };

  const addWishlist = (id, id2, id3) => {
    if (storedData.userLoginSuccess) {
      const url = getWhishlist();
      const userData = {
        user_id: storedData.userId,
        product_id: id,
        has_login: "yes",
      };

      axios
        .post(url, userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            toast.success("Wishlist Added", { icon: "❤️" });
            fetchProduct(categoryById);
            window.fbq('track','Add to wishlist',{currency:'INR', value:id2, content_name:id3})
          } else {
            toast.error("Something Went Wrong", { icon: "⚠️" });
          }

          console.log(" the value of list", resp.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong", { icon: "⚠️" });
        });
    } else {
      // alert("Please Login");
      // navigate("/signin");
      setShowModal(true)

    }
  };

  const removeWishlist = (id) => {
    const url = deleteWishList(id);

    let data = new FormData();
    data.append("_method", "DELETE");

    // console.log(" ==> wishlist data => ", url, data);

    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          toast.success("Wishlist Removed", { icon: "✅" });
          fetchProduct(categoryById);
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }

        console.log(" the value of list", resp.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong", { icon: "⚠️" });
      });
  };

  const addtoCart = (id) => {
    const url = getcartId();
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          fetchProduct(categoryById);
          setCartValues(true);
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        console.log(" === error ===", error);
      });
  };

  const fetchProductFilters = () => {
    var brandArr = JSON.parse(
      window.localStorage.getItem("brandsCheckdDataLocal")
    );
    var resbrandArr = JSON.parse(
      window.localStorage.getItem("resbrandsCheckdDataLocal")
    );

    const url = getBrandFilter();
    var body = {
      brand_id: brandID,
    };
    console.log("---filter body", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("product  filter --> ", res.data);
        setbrandsfilterData(res.data.data[0].brands);
        setCategoriesfilterData(res.data.data[0].categories);
        setbrandCategoryFIlter(res.data.data[0].categories);
        setpackssfilterData(res.data.data[0].packs);
        // console.log(" inds d ==> ", brandsfilterData, CategoriesfilterData, packssfilterData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var categoryValue = JSON.parse(localStorage.getItem("selectedItems")) || [];

  function handleCategoriesChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...categoryValue]; // Create a separate array
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    categoryValue = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  var packValue = JSON.parse(localStorage.getItem("selectedItems2")) || [];
  function handlePacksChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...packValue];
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems2", JSON.stringify(selectedItems));
    packValue = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  var categoryValue2 = JSON.parse(localStorage.getItem("selectedItems")) || [];
  function respHandleCategoriesChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...categoryValue]; // Create a separate array
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    categoryValue2 = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  var packValue2 = JSON.parse(localStorage.getItem("selectedItems2")) || [];
  function respHandlePacksChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...packValue];
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems2", JSON.stringify(selectedItems));
    packValue2 = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  // const respHandleCategoriesChange = async (event) => {
  //     var updatedList = [...checkedCategoriesfilterData];
  //     if (event.target.checked) {
  //         updatedList = [...checkedCategoriesfilterData, event.target.value];
  //     } else {
  //         updatedList.splice(
  //             checkedCategoriesfilterData.indexOf(event.target.value),
  //             1
  //         );
  //     }
  //     setcheckedCategoriesfilterData(updatedList);
  //     const categoriesChecked = JSON.stringify(updatedList);
  //     window.localStorage.setItem("resCategoryCheckdDataLocal", categoriesChecked);
  //     var items = document.getElementsByName("resbrandinputCheck");
  //     var arr = [];
  //     for (var i = 0; i < items.length; i++) {
  //         if (items[i].type == "checkbox" && items[i].checked == true) {
  //             arr.push(items[i].value);
  //         }
  //     }
  //     localStorage.setItem("resbrandvalue", arr);
  //     fetchProduct();
  //     setCurrentPage(1);
  //     console.log(" categories filter datat ==> ", checkedCategoriesfilterData);
  // };

  // const respHandlePacksChange = (event) => {
  //     localStorage.clear('resbcvalue')
  //     var updatedList = [...checkedpackssfilterData];
  //     if (event.target.checked) {
  //       updatedList = [...checkedpackssfilterData, event.target.value];
  //     } else {
  //       updatedList.splice(
  //         checkedpackssfilterData.indexOf(event.target.value),
  //         1
  //       );
  //     }
  //     setcheckedpackssfilterData(updatedList);
  //     const categoriesChecked = JSON.stringify(updatedList);
  //     window.localStorage.setItem("respackCheckedDataLocal", categoriesChecked);

  //     var items = document.getElementsByName("PacksinputCheck");
  //     var arr = [];
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].type == "checkbox" && items[i].checked == true) {
  //         arr.push(items[i].value);
  //       }
  //     }
  //     localStorage.setItem("respacksvalue", arr);

  //     fetchProduct();
  //     console.log(" packs filter datat ==> ", checkedpackssfilterData);
  //   };

  const clearAllFilters = () => {
    categoryValue = []
    packValue = []
    categoryValue2 = []
    packValue2 = []
    fetchProduct();
  };

  const handleAddToCart = (id, id2, id3, id4) => {
    if (storedData.userLoginSuccess) {
      if(id4 <= 0){
        toast(' out of stock')
      }else{
        console.log(id4);
        
        addtoCart(id);
        window.fbq('track','Add to cart',{currency:'INR',value:id2,content_name:id3})
      }


    } else {
      // alert("Please Login ");
      // navigate("/signin");
      setShowModal(true)

    }
  };

  // pegination
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    console.log("next page");
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    console.log("prev page");
    window.scrollTo(0, 0);
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = prodData.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = prodData.length;



  const handlePageChange = (selectedPage) => {
    setisLoding(true)
    setTimeout(() => {
      setisLoding(false)
      setCurrentPage(selectedPage.selected + 1);
    }, 1000);
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  };

  // const handlePageChange = (selectedPage) => {
  //   setCurrentPage(selectedPage.selected + 1);
  //   window.history.scrollRestoration = "manual";
  //   window.scrollTo(0, 0);
  // };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  var brandsArryCheckpbox = localStorage.getItem("BPbrandsCheckdDataLocal");
  // var categoriesArryCheckbox = localStorage.getItem("BrandsCategoryCheckdDataLocal");
  var packArryCheckbox = localStorage.getItem("BrandspackCheckedDataLocal");

  const handleShowAllCategories = () => {
    setNumCategoriesToShow(CategoriesfilterData.length);
  };

  const handleShowLessCategories = () => {
    setNumCategoriesToShow(5);
  };

  var respackArr = localStorage.getItem("respacksvalue");
  var resCategoryData = localStorage.getItem("resbrandvalue");
  var packArryCheckbox = localStorage.getItem("packbcValue");

  const handleCloseModal = () => {
    setShowModal(false);
  };
 
  const toLoginPage =()=>{
    navigate('/signin')
  }

  // responsive filter function
  const RespFIlterComponent = () => {
    return (
      <div className="container productCon" style={{ padding: "20px" }}>
        <div className="row">
          <div className=" col-12 ProductFirstDivv">
            <div>
              <div className="row">
                <div className="col-5">
                  <div className="sortCont">
                    {
                      brandID != 31 && brandID != 19 && (<>
                    <p className="sortTitle">filter by Packs</p>
                      </>) 
                    }
                    {packssfilterData.map((item) => {
                      return (
                        <>
                          <div className="checkCon">
                            <label>
                              <input
                                onChange={respHandlePacksChange}
                                checked={
                                  packValue2 == null
                                    ? null
                                    : packValue2.includes(item.pack)
                                }
                                value={item.pack}
                                type="checkbox"
                                id={item.pack}
                                name="PacksinputCheck"
                              />
                              Pack of {item.pack}
                            </label>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="col-7">
                  <div className="sortCont">
                    <p className="sortTitle">FIlter by Categories</p>
                    {brandCategoryFIlter.map((item) => {
                      return (
                        <>
                          <div className="checkCon">
                            <label>
                              <input
                                onChange={respHandleCategoriesChange}
                                checked={
                                  categoryValue2 == null
                                    ? null
                                    : categoryValue2.includes(item.id)
                                }
                                value={item.id}
                                type="checkbox"
                                id={item.id}
                                name="resbrandinputCheck"
                              />
                              {item.name}
                            </label>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleModalClose = () => {
    fetchProduct(categoryById)
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log("Modal is closed, running effect in CartPage...");
  };

  return (
    <div>
      <NavMenu
        onCloseModal={handleModalClose}
        cartBoolean={cartValues}
        setCartBoolean={setCartValues}
      />
      <Toaster />
      {filtercom ? <RespFIlterComponent /> : null}
      {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'white'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<>
            <div className="container productCon">
        {btnshow ? (
          <div>
            {" "}
            <button
              onClick={() => clearAllFilters()}
              style={{
                display: "block",
                margin: "30px auto",
                marginTop: "0px",
                padding: "10px 20px",
                borderRadius: "14px",
                zIndex: "10",
                background: "grey",
                border: "2px",
                color: "white",
                width: "90%",
              }}
            >
              Clear All
            </button>{" "}
            <button
              onClick={() => displayProduct()}
              style={{
                display: "block",
                margin: "auto",
                marginTop: "0px",
                padding: "10px 20px",
                borderRadius: "14px",
                zIndex: "10",
                background: "#E51515",
                border: "2px",
                color: "white",
                width: "90%",
              }}
            >
              APPLY
            </button>
          </div>
        ) : null}
        <div className="row">
          {
            brandID == 19 || brandID == 31 ? null:(<><div className="col-3 ProductFirstDiv">
            <div>
              {/* <div className="sortCont">-
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className="sortTitle">Filter by Brands</p>
                                    <div className="checkCon" style={{ marginTop: "5px" }}>
                                        <label onClick={() => clearAllFilters()} for="scales">
                                            Clear All
                                        </label>
                                    </div>
                                </div>

                                {brandsfilterData.map((item) => {
                                    return (
                                    <>
                                        <div className="checkCon">
                                        <input
                                            
                                            checked={
                                            brandsArryCheckpbox == null
                                                ? null
                                                : brandsArryCheckpbox.includes(item.id)
                                            }
                                            value={item.id}
                                            type="checkbox"
                                            id={item.id}
                                            name="inputCheck"
                                            defaultChecked={2}
                                        />
                                        <labl for="scales">{item.brand_name}</label>
                                        </div>
                                        <hr />
                                    </>
                                    );
                                })}
                            </div> */}
                               {
                      brandID != 31 && brandID !=19 && (<> <div className="sortCont">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                          
                        <p className="sortTitle">Filter by Categories</p>
                        <button
                    onClick={() => clearAllFilters()}
                    style={{
                      display: "block",
                      marginTop: "0px",
                      border:'none',
                      background:'white'
                    }}
                  >
                    Clear All
                  </button>
                      </div>
      
                      {CategoriesfilterData.map((item) => {
                        return (
                          <>
                            <div className="checkCon">
                              <label>
                                <input
                                  onChange={handleCategoriesChange}
                                  //  checked={categoriesArr == null ? null : categoriesArr.includes(item.id)}
                                  checked={
                                    categoryValue == null
                                      ? null
                                      : categoryValue.includes(item.id)
                                  }
                                  value={item.id}
                                  type="checkbox"
                                  id={item.id}
                                  name="brandinputCheck"
                                />
                                {item.name}
                              </label>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    </div></>)}
             
              <div className="sortCont">
              {
                      brandID != 31 && brandID !=19 && (<>
                    <p className="sortTitle">filter by Packs</p>
                      </>) 
                    }
                {/* <p className="sortTitle">FIlter by Packs</p> */}
                {packssfilterData.map((item) => {
                  console.log('item of the pack',item);
                  return (
                    <>
                      <div className="checkCon">
                        <label>
                          <input
                            onChange={handlePacksChange}
                            checked={
                              packValue == null
                                ? null
                                : packValue.includes(item.pack)
                            }
                            value={item.pack}
                            type="checkbox"
                            id={item.pack}
                            name="PacksinputCheck"
                          />
                          Pack of {item.pack}
                        </label>
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
              {/* <div>
                                <p className="sortTitle">price</p>
                                <div className="checkCon">
                                    <input type="checkbox" id="scales" name="scales" />
                                    <label for="scales">0-100</label>
                                </div>
                                <div className="checkCon">
                                    <input type="checkbox" id="horns" name="horns" />
                                    <label for="horns">0-99</label>
                                </div>
                                <div className="checkCon">
                                    <input type="checkbox" id="horns" name="horns" />
                                    <label for="horns">100-150</label>
                                </div>
                                <div className="checkCon">
                                    <input type="checkbox" id="horns" name="horns" />
                                    <label for="horns">200-300</label>
                                </div>
                            </div> */}
            </div>
            {/* <img
                            src={img}
                            width="226px"
                            height="380px"
                            className="sideColumImg"
                        /> */}
          </div> </>)
          }
          
          <div className={`${brandID == 19 || brandID == 31 ? 'col-lg-12 ':'col-lg-9 '} col-12 ProductSecondDiv `}>
            <div className="mb-3 minsort">
              <div className="iconGrid">
                {/* <TbLayoutGrid  onClick={() => {
                                        gridFirstFunc();
                                        // gridFourFuc()
                                        
                                    }} fontSize={20}/>
                                    
                               
                                <TfiLayoutGrid3 onClick={() => {
                                        gridTwoFuc();
                                        // gridThreeFuc()

                                    }} fontSize={20} className="tfiLayout"/>
                                
                           
                                <p style={{ marginBottom: 0 }} className="listResult">
                                    Showing 1 to 9 of 37 items
                                </p> */}
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                  }}
                >
                  <BsListTask
                    onClick={() => {
                      gridFirstFunc();
                    }}
                    style={{ marginRight: "50px" }}
                    fontSize={22}
                    color={isActive2 ? "rgb(189, 39, 50)" : "gray"}
                  />

                  <TbLayoutGrid
                    onClick={() => {
                      gridTwoFuc();
                    }}
                    fontSize={20}
                    className="tfiLayout"
                    color={isActive ? "rgb(189, 39, 50)" : "gray"}
                  />
                </div>
                <div style={{ width: "30%" }} class="input-group mySearch">
                  <input
                    type="text"
                    class="form-control mySearch2"
                    placeholder="Search"
                    aria-label="Recipien"
                    aria-describedby="basic-addon2"
                    style={{ textTransform: "uppercase" }}
                    onChange={(e) => setseachValue(e.target.value)}
                  />
                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    onClick={() => {
                      fetchProduct();
                      console.log("hello");
                    }}
                  >
                    <BiSearch />
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginLeft: "90px",
                  }}
                >
                  <p style={{ marginBottom: 0 }}>Sort by:</p>
                  <Dropdown className="secondProductBtn">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Date, old to new
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {/* <div className="mobilSort">
                            <div className="row mobilSortRow">
                                <div className="col-8">
                                    <p className="sortIconAndText">
                                        <span>
                                            <TbLayoutGrid onClick={() => {
                                                gridFirstFunc();

                                            }} />
                                            <TfiLayoutGrid3 onClick={() => {
                                                gridTwoFuc();

                                            }} />
                                        </span>.
                                        Showing 1 to 9 of 37 items
                                    </p>
                                </div>
                                <div className="col-4 filterSpan">
                                    <span>Sort by</span>
                                    <span>Filter</span>
                                </div>
                            </div>
                        </div> */}

            {/* new mobile sort  */}
            <div className="mobilSort">
              {mobileFilter ? (
                <div className="row mobilSortRow">
                  <div className="col-8 anotherSpan">
                    <p className="sortIconAndText">
                      <span>
                        <BsListTask
                          onClick={() => {
                            gridFirstFunc();
                          }}
                          color={isActive2 ? "rgb(189, 39, 50)" : "gray"}
                          fontSize={22}
                        />

                        <TbLayoutGrid
                          onClick={() => {
                            gridTwoFuc();
                          }}
                          color={isActive ? "rgb(189, 39, 50)" : "gray"}
                        />
                      </span>
                      <div
                        style={{ width: "60%" }}
                        class="input-group mySearch"
                      >
                        <input
                          type="text"
                          class="form-control mySearch2"
                          placeholder="Search"
                          aria-label="Recipien"
                          aria-describedby="basic-addon2"
                          style={{ textTransform: "uppercase" }}
                          onChange={(e) => setseachValue(e.target.value)}
                        />
                        <span
                          class="input-group-text"
                          id="basic-addon2"
                          onClick={() => {
                            fetchProduct();
                            console.log("hello");
                          }}
                        >
                          <BiSearch />
                        </span>
                      </div>
                    </p>
                  </div>
                  <div className="col-4 filterSpan">
                    <span
                      style={{ marginTop: "10px" }}
                      onClick={() => resFilterFunc()}
                    >
                      Filter
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            {isLoding ? (
                <div
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <TailSpin
                    style={{
                      width: "100%",
                      border: "2px solid black",
                      margin: "auto",
                      display: "block",
                    }}
                    height="70"
                    width="70"
                    color="#ffd11b"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />{" "}
                </div>
              ) : (<>
               <div>
              {showProduct ? (
                <div className={` ${
                  gridFour ? noProdDataFound ? "noPRoduct" : "productCradContainer" : "productCradContainer2"
                }`}>
                  {
                    noProdDataFound ? (<> {" "}
                    <p style={{fontSize:'30px'}}>No Product Found</p>{" "}</>):(<> {currentItems.map((item) => {
                    return (
                      <div
                        className={`productCard ${
                          gridFour ? "GridFourCard" : "GridThreeCard"
                        }`}
                      >
                        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            style={{
                              display: "block",
                              backgroundColor: "#EEEEEE",
                            }}
                          >
                            <div
                              onClick={() => sendProdId(item?.id)}
                              className="productPageImage"
                              style={{
                                backgroundRepeat: "no-repeat",
                                backgroundAttachment: "inherit",
                                mixBlendMode: "multiply",
                                backgroundSize: "90% 90%",
                                padding: 10,
                                backgroundPosition: "center",
                                backgroundOrigin: "content-box",
                                backgroundPositionX: "center",
                              }}
                            >
                              <img
                                alt={item.title}
                                src={item.image_url}
                                style={{ width: "100%" }}
                              />
                              {/* {item.stock > 0 && item.stock <= 10 && (
                                                                            <p className='itemStock'> Only {item.stock}</p>
                                                                            )}

                                                                            {item.stock > 10 && (
                                                                            null
                                                                            )}

                                                                            {item.stock <= 0 && (
                                                                            <p className='itemStock'>Out of Stock</p>
                                                                            )} */}
                              {item.sp_p ? (
                                <p className="itemStock">{item.sp_name}</p>
                              ) : null}
                            </div>
                          </div>
                        </a>

                        <p className="productCardReview">
                          <div className="feratureStar">
                            <ReactStars
                              edit={false}
                              value={item.rating}
                              count={5}
                              size={18}
                            />
                            <div style={{ paddingRight: 20, marginTop: "5%" }}>
                              {item?.wishlist_id == null ? (
                                <i
                                  class="fa-solid fa-heart"
                                  onClick={() => {addWishlist(item?.id, item.starting_price, item.title)}}
                                  style={{
                                    color: "lightslategray",
                                    fontSize: "18px",
                                  }}
                                ></i>
                              ) : (
                                <i
                                  class="fa-solid fa-heart"
                                  onClick={() =>
                                    removeWishlist(item?.wishlist_id)
                                  }
                                  style={{ color: "red", fontSize: "18px" }}
                                ></i>
                              )}
                            </div>
                          </div>
                        </p>
                        <hr />
                        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            className="ProductCardText"
                            onClick={() => {
                              sendProdId(item?.id);
                            }}
                          >
                            {item.title}
                            {/* {item.title.split(" ").slice(0, 8).join(" ")}
                            {item.title.split(" ").length > 8 ? "..." : ""} */}
                          </p>
                          <hr />
                          <span
                            className="featureOffer"
                            onClick={() => sendProdId(item?.product_id)}
                          >
                            <strong>
                              {parseInt(
                                ((item.mrp - item.starting_price) / item.mrp) *
                                  100
                              )}
                              % Off
                            </strong>
                          </span>
                          <p style={{margin:'0px 10px -5px ', color:'black', fontSize:'14px', fontWeight:'500'}}>Our Price</p>
                                <span
                                  onClick={() => {
                                    sendProdId(item.id);
                                  }}
                                  className="productMrp"
                                >
                                  <p
                                    className="productCardPrice"
                                    onClick={() => {
                                      sendProdId(item.id);
                                    }}
                                  >
                                   ₹<span className="productCardPriceMain">{item.starting_price}</span>
                                  </p>
                                  <p
                                    className="productCardPrice2"
                                    onClick={() => {
                                      sendProdId(item.id);
                                    }}
                                  >
                                    MRP {" "}
                                    <strike style={{ fontSize: "14px" }}>
                                    ₹{item.mrp}
                                    </strike>
                                  </p>
                                </span>
                        </a>
                        {item?.cart_id == null ? (
                          <button
                            onClick={() => {handleAddToCart(item.id, item.starting_price, item.title, item.stock)}}
                            className="productCardBtn"
                          >
                            <i
                              class="fa-solid fa-cart-shopping"
                              style={{ fontSize: "14px" }}
                            ></i>
                            Add to cart
                          </button>
                        ) : (
                          <button
                            onClick={() => navigate("/cart")}
                            className="productCardBtn"
                          >
                            View in cart
                          </button>
                        )}
                      </div>
                    );
                  })}</>)
                  }
                 
                </div>
              ) : null}
                 </div>
                  </>
              )}
                  
              {/* pegination  */}
              {!noProdDataFound ? (
           <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
            <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active-page1'}
            forcePage={currentPage - 1}
          />
          </div>):null}
           
           

          </div>
        </div>
      </div>
      <Footer />
          </>)}
          <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className='modalClass modalClass2'>
       
       <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
        <p>Please Login!</p>
        <div style={{display:'flex', justifyContent:'end'}}>
        <button onClick={toLoginPage} style={{background:'red', borderRadius:'10px', color:'white', border:'none', padding:'5px 10px'}}>Login</button>
        </div>
       </Modal.Body>
     </Modal>
    </div>
  );
}
