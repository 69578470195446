import React, { useEffect, useState } from "react";
import "../assets/style.css";
// import dataTwo from '../Json/dataTwo'
import "../assets/responsive.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getSimilarProduct, getSimilarProduct2, getSimilarProductdewalt } from "../api/api";
import axios from "axios";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  getWhishlist,
  getSpecialCategoryApi,
  getcartId,
  deleteWishList,
} from "../api/api";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Related = ({ pId, cId,cartBoolean, setCartBoolean, brandName, brandId }) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [dicount, setDiscount] = useState([]);

  const storedData = useSelector((state) => state.profile);

  var productId = pId;
  var categoryId = cId;

  useEffect(() => {
    console.log(brandName);
    if(brandName === 'XTRABOND'){
      getSimilarValue2(storedData.userId);
    }else if(brandName === 'LOOZEN' || brandName === 'DEWALT' ){
      getSimilarValue3(storedData.userId);
    }
    else{
      getSimilarValue(storedData.userId);
    }
    const handleWindowResize = () => {
      const screenWidth = window.innerWidth;
      setCenterMode(screenWidth >= 420);
    };

  }, []);

  const getSimilarValue = () => {
    const url = getSimilarProduct();

    const body = {
      product_id: productId,
      user_id: storedData.userId,
      category_id: cId,
    };
    console.log('body--similar',body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("-----realteddd", resp.data);
        setProduct(resp.data.data);
        // setDiscount(resp.data.data[0].hot_products.data[0])
      })
      .catch((error) => {
        console.log("--related error", error);
      });
  };
  const getSimilarValue2 = () => {
    const url = getSimilarProduct2();

    const body = {
      product_id: productId,
      user_id: storedData.userId,
      pcategory: [cId],
    };
    console.log('body--similar2',body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("-----realteddd", resp.data);
        setProduct(resp.data.data);
        // setDiscount(resp.data.data[0].hot_products.data[0])
      })
      .catch((error) => {
        console.log("--related error", error);
      });
  };
  const getSimilarValue3 = () => {
    const url = getSimilarProductdewalt();

    const body = {
      product_id: productId,
      user_id: storedData.userId,
      brand_id: brandId,
    };
    console.log('body--similar2',body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("-----realteddd", resp.data);
        setProduct(resp.data.data);
        // setDiscount(resp.data.data[0].hot_products.data[0])
      })
      .catch((error) => {
        console.log("--related error", error);
      });
  };

  const addWishlist = (id, id2, id3) => {
    if (storedData.userLoginSuccess) {
      const url = getWhishlist();
      const userData = {
        user_id: storedData.userId,
        product_id: id,
        has_login: "true",
      };
      // console.log("----user", userData);
      axios
        .post(url, userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            toast.success("Wishlist Added", { icon: "❤️" });
            getSimilarValue(storedData.userId);
            window.fbq('track','Add to wishlist',{currency:'INR', value:id2, content_name:id3});
          } else {
            console.log("-----error");
            toast.error("Something Went Wrong", { icon: "⚠️" });
          }

          // console.log(' the value of list',resp.data);
        })
        .catch((err) => {
          console.log("-----error", err);

          toast.error("Something Went Wrong", { icon: "⚠️" });
        });
    } else {
      alert("Please Login");
      navigate("/signin");
    }
  };

  const removeWishlist = (id) => {
    const url = deleteWishList(id);

    let data = new FormData();
    data.append("_method", "DELETE");

    // console.log(" ==> wishlist data => ", url, data);

    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          toast.success("Wishlist Removed", { icon: "✅" });
          getSimilarValue(storedData.userId);
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }

        // console.log(" the value of list", resp.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong", { icon: "⚠️" });
      });
  };

  const addtoCart = (id) => {
    const url = getcartId();

    // console.log(" -- - - - url - - - ", url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          if (storedData.userLoginSuccess) {
            getSimilarValue(storedData.userId);
            setCartBoolean(true)
          } else {
            getSimilarValue(null);
          }
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        console.log(" === error ===", error);
      });
  };

  const handleAddToCart = (id, id2,id3) => {
    if (storedData.userLoginSuccess) {
      addtoCart(id);
      window.fbq('track','Add to cart',{currency:'INR',value:id2,content_name:id3})

    } else {
      alert("Please Login");
      navigate("/signin");
    }
  };

  var discoutPrice = product.starting_price - product.mrp;
  var dpPrice = discoutPrice / product.starting_price;
  var finalPrice = dpPrice * 100;

  // console.log("final price ==> ", finalPrice);

  const refresh = () => window.location.reload(true);

  const sendProdId = (id) => {
    // console.log(" ------ id", id);
    // navigate(`/product/${id}`, { state: { productId: id } });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  const [centerMode, setCenterMode] = useState(false);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 840, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  if (product.length === 2) {
    sliderSettings.slidesToShow = 2;
  } else if(product.length === 3){
    sliderSettings.slidesToShow = 3
  }
   else if(product.length === 1){
    sliderSettings.slidesToShow = 1
  }
  else {
    sliderSettings.slidesToShow = 4; // Set the default to show 4 products
  }

  const cardContainerClassName = sliderSettings.slidesToShow === 3
  ? 'cardContainer1 custom col-9'
  : (sliderSettings.slidesToShow === 2
      ? 'cardContainer1 modifiedClass col-9'
      : sliderSettings.slidesToShow === 1 ? 'cardContainer1 modifiedClass2 col-9': 'cardContainer1 col-9');
  return (
    <div>
      <Toaster />
      <div className={cardContainerClassName}>
        <h3
          className=" textRelated"
        >
          Similar Products
        </h3>

        <Slider className="featureCaurnew" {...sliderSettings}>
          {product.map((item, index) => {
          
            return (
              <>
                <div
                  // onClick={() => sendProdId(item.product_id)}

                  className="featureCard2"
                  key={item.id}
                >
                  <a  href={`/product/${item.id}`} style={{textDecoration:'none'}}>
                  <div style={{ display: "block", backgroundColor: "#EEEEEE" }}>
                    {/* <img src={item.img} onClick={() => sendProdId(item.product_id)} style={{width: '200px',height: 'auto', backgroundColor: 'green', padding: 60}} />  */}
                    <div
                      onClick={() => sendProdId(item.id)}
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        mixBlendMode: "multiply",
                        backgroundSize: "90% 90%",
                        padding: 10,
                        backgroundPosition: "center",
                        backgroundOrigin: "content-box",
                        backgroundPositionX: "center",
                      }}
                    >
                      <img src={item.image_url} style={{ width: "100%" }} alt={item.title} />
                      {/* <span className={`carding ${item.wishlist_id == null ? '' : 'clicked'}`}   onClick={() => addWishlist(item.id)}>
                              <i class="fa-solid fa-heart"  ></i>
                              </span> */}
                      {item.stock > 0 && item.stock <= 10 && (
                        <p className="itemStock"> Only {item.stock}</p>
                      )}

                      {item.stock > 10 &&
                        // <p className='itemStock'>null</p>
                        null}

                      {item.stock <= 0 && (
                        <p className="itemStock">Out of Stock</p>
                      )}
                    </div>

                    {/* <img src={item.image_url} className='featureImg' /> */}
                  </div>
</a>
                  <div className="feratureStar">
                    <ReactStars
                      edit={false}
                      value={item.rating}
                      count={5}
                      size={18}
                    />
                    {
                      brandName !== 'XTRABOND' ? (<> <div style={{ paddingRight: 20, marginTop: "5%" }}>
                      {item.wishlist_id == null ? (
                        <i
                          class="fa-solid fa-heart"
                          onClick={() => {addWishlist(item.id, item.starting_price, item.title)}}
                          style={{ color: "lightslategray", fontSize: "18px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-heart"
                          onClick={() => removeWishlist(item.wishlist_id)}
                          style={{ color: "red", fontSize: "18px" }}
                        ></i>
                      )}
                    </div></>):(<> <div style={{ paddingRight: 20, marginTop: "5%", visibility:'hidden' }}>
                      {item.wishlist_id == null ? (
                        <i
                          class="fa-solid fa-heart"
                          onClick={() => {addWishlist(item.id);window.fbq('track','Add to wishlist',{currency:'INR', value:item.starting_price, content_name:item.title})}}
                          style={{ color: "lightslategray", fontSize: "18px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-heart"
                          onClick={() => removeWishlist(item.wishlist_id)}
                          style={{ color: "red", fontSize: "18px" }}
                        ></i>
                      )}
                    </div></>)
                    }
                   
                  </div>
                  <a  href={`/product/${item.id}`} style={{textDecoration:'none'}}>
                  <hr
                    className="featureHr"
                    onClick={() => sendProdId(item.id)}
                  />
                     <OverlayTrigger
                                 placement="bottom"  
                                 overlay={
                                   <Tooltip style={{ backgroundColor: '#333', color: '#fff', borderRadius: '4px', fontSize: '12px' }}>
                                     {item.title}
                                   </Tooltip>}
                               >
                  <p
                    className="featureText"
                    onClick={() => sendProdId(item.id)}
                  >
                    {item.title}
                    {/* {item.product_name.split(' ').slice(0, 3).join(' ')}
                        {item.product_name.split(' ').length > 3 ? '...' : ''} */}
                  </p>
                  </OverlayTrigger>
                  <hr
                    className="featureHr"
                    onClick={() => sendProdId(item.id)}
                  />
                  </a>
                  {
                    brandName !== 'XTRABOND' ? (<><a href={`/product/${item.id}`} style={{textDecoration:'none'}}><div style={{ textAlign: "initial" }}>
                    {" "}
                    <span
                      className="featureOffer"
                      onClick={() => sendProdId(item.product_id)}
                    >
                      <strong>
                        {parseInt(
                          ((item.mrp - item.starting_price) /
                            item.mrp) *
                            100
                        )}
                        % Off
                      </strong>
                    </span>{" "}
                  </div>
                  <p className="ourPrice">Our Price</p>
                  <div
                    className="featurePrizeContainer"
                    onClick={() => sendProdId(item.id)}
                    >
                    <span
                      className="featurePrize fearut"
                      onClick={() => sendProdId(item.id)}
                    >
                      ₹ {item.starting_price}
                    </span>
                    <span
                      className="featutreFinalpries2"
                      onClick={() => sendProdId(item.id)}
                    >
                      {" "}
                      MRP <strike>₹{item.mrp}</strike>
                    </span>
                  </div></a></>):(<><a style={{textDecoration:'none'}}>
                    <div style={{ textAlign: "initial", visibility:'hidden' }}>
                    {" "}
                    <span
                      className="featureOffer"
                      onClick={() => sendProdId(item.product_id)}
                    >
                      <strong>
                        {parseInt(
                          ((item.mrp - item.starting_price) /
                            item.mrp) *
                            100
                        )}
                        % Off
                      </strong>
                    </span>{" "}
                  </div>
                  <p  style={{visibility:'hidden'}} className="ourPrice" >Our Price</p>
                  <div style={{display:'none'}}
                    className="featurePrizeContainer"
                    onClick={() => sendProdId(item.id)}
                    >
                    <span
                      className="featurePrize fearut"
                      onClick={() => sendProdId(item.id)}
                    >
                      ₹ {item.starting_price}
                    </span>
                    <span
                      className="featutreFinalpries2"
                      onClick={() => sendProdId(item.id)}
                    >
                      {" "}
                      MRP <strike>₹{item.mrp}</strike>
                    </span>
                  </div></a></>)
                  }
                  

                  {
                    brandName !== 'XTRABOND' ? (<>{item.cart_id == null ? (
                      <button
                        onClick={() => {handleAddToCart(item.id, item.starting_price, item.title)}}
                        className="Featurebtn"
                      >
                        <i
                          class="fa-solid fa-cart-shopping"
                          style={{ fontSize: "14px" }}
                        ></i>
                        Add to cart
                      </button>
                    ) : (
                      <button
                        onClick={() => navigate("/cart")}
                        className="Featurebtn"
                      >
                        View in cart
                      </button>
                    )}</>):(<><a href={`/product/${item.id}`} style={{textDecoration:'none'}}> <button
                      className="Featurebtn"
                    >
                     View Product
                    </button></a></>)
                  }
                  
                </div>
              </>
            );
          })}
          {/* </Carousel> */}
        </Slider>
      </div>
    </div>
  );
};

export default Related;
